@import "../../../ui/variables";

.wrapper {
    $base-width: 50vw;
    box-shadow: 0 -10px 10px -5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    font-size: 1.3em;
    color: $darkBlue;
    letter-spacing: 1px;
    justify-content: center;
    min-width: calc(#{$base-width} - (2 * #{$spacing}));
    --img-width: 300px;

    &.landscape {
        --img-width: 500px;
    }

    @include OpenSansRegular;

    &.with_img > p {
        min-width: calc(#{$base-width} - var(--img-width) - (2 * #{$spacing}));
    }

    &.with_double_img > p {
        min-width: calc(#{$base-width} - (2 * (var(--img-width) + #{$spacing})));
    }

    > * {
        margin: 2 * $spacing;
    }

    p {
        max-width: 800px;
        font-size: 0.85em;
        white-space: pre-line;

        a {
            letter-spacing: unset;
            font-weight: 400;
            text-decoration: underline;

            &:after {
                display: none;
            }
        }
    }

    .img_wrapper {
        width: var(--img-width);
        min-width: 300px;
        margin-left: 0;
        margin-right: 0;

        i {
            font-size: 16px;
            text-align: center;
            display: block;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        $base-width: 50vw;
        $img-width: 300px;

        flex-direction: column;
        min-width: calc(#{$base-width} - (2 * #{$mobile-spacing}));

        &.with_img > p {
            min-width: calc(#{$base-width} - #{$img-width} - (2 * #{$mobile-spacing}));
        }

        &.with_double_img > p {
            min-width: calc(#{$base-width} - (2 * (#{$img-width} + #{$mobile-spacing})));
        }

        p {
            text-align: center;
            font-size: 0.6em;
        }

        > * {
            margin: 2 * $mobile-spacing;
        }

        .img_wrapper {
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
        }
    }
}
