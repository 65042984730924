@import "../../../../ui/variables";

.progressbar {
    position: relative;
}

.wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 2 * $spacing;
    padding-bottom: 2 * $spacing;

    .column {
        display: flex;
        flex-direction: column;

        &:first-of-type {
            flex: unset;
            margin-right: 2 * $spacing;
        }

        .image {
            $size: 300px;
            width: $size;
            height: $size;
            margin-bottom: $spacing;
            text-align: center;

            &.hidden {
                background: $lightBlue;

                img {
                    display: none;
                }

                .title {
                    color: $offWhite;
                    margin-bottom: 0;
                }
            }

            img {
                max-width: $size;
                max-height: $size;
            }

            .title {
                display: none;
            }
        }

        .detail {
            display: flex;
            flex-direction: column;
            justify-content: center;

            button {
                font-size: 12px;
                width: min-content;
                margin: $spacing auto 0;
            }

            .price {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .amount {
                    white-space: nowrap;
                    font-size: 24px;
                    margin-bottom: $spacing * 0.5;
                    @include MerriweatherBold;
                }

                .additional {
                    white-space: nowrap;
                    font-size: 14px;
                    @include OpenSansRegular;
                }
            }
        }

        .title {
            @include MerriweatherBold;
            font-size: 24px;
            margin-bottom: $spacing * 0.5;
        }

        .description {
            @include OpenSansRegular;
            white-space: pre-line;
            font-size: 1em;
        }
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column;

        .column:first-of-type {
            text-align: center;
            margin-right: 0;
            margin-bottom: $spacing * 2;

            .image,
            .image img {
                width: 100%;
                height: auto;
            }
        }
    }
}
