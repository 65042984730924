@import "../../../../ui/variables";

.page {
    @include OpenSansRegular;
    box-shadow: 0 -10px 10px -5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .text {
        margin: 2 * $spacing;
        max-width: 800px;

        .title {
            @include MerriweatherBold;
            font-size: 26px;
            margin-bottom: $spacing * 0.5;
        }

        .desc {
            font-size: 18px;
        }
    }
}
