@import "../../../ui/variables";

.button {
    font-size: 14px;
    text-transform: uppercase;
    color: $offWhite;
    border: none;
    letter-spacing: 2px;
    padding: 8px 20px;
    margin-top: 15px;
    cursor: pointer;
    transition: $transition;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    user-select: none;
    background: $darkBlue;
    box-shadow: 0 0 4px 2px darkBlue(0.1);
    width: 100%;
    @include ripple($offWhite);
    @include OpenSansBold;

    &:focus,
    &:hover {
        background: darkBlue(0.8);
        box-shadow: 0 2px 5px 2px darkBlue(0.1);
    }

    &.loading {
        pointer-events: none;
        background: darkBlue(0.8);
    }
}
