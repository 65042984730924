@import "../../../ui/variables";

@keyframes slide-up {
    0% {
        opacity: 0;
        bottom: -60px;
    }

    100% {
        opacity: 1;
        bottom: 0;
    }
}

@keyframes slide-down {
    0% {
        opacity: 1;
        bottom: 0;
    }

    100% {
        opacity: 0;
        bottom: -60px;
    }
}

.wrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    user-select: none;
    overflow-y: hidden;
    height: calc(100vh - 60px);
    z-index: 9999;
    width: 100vw;
    pointer-events: none;

    .snackbar {
        min-width: 300px;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0px rgba(0, 0, 0, 0.14), 0 1px 18px 0px rgba(0, 0, 0, 0.12);
        background: #0c1618;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        border-radius: 3px;
        white-space: nowrap;
        padding: 8px 24px;
        letter-spacing: 1px;
        animation: slide-up 0.2s ease;
        margin: 12px;
        min-height: 48px;
        pointer-events: all;
        bottom: 0;
        position: absolute;

        &.being_deleted {
            animation: slide-down 0.2s ease;
        }

        &.success {
            background: #28a745;
            color: white;
        }

        &.error {
            background: #dc3545;
            color: white;
        }

        &.warning {
            background: #ffc107;
        }

        &.neutral {
            background: $darkBlue;
            color: $offWhite;
        }

        div {
            height: 36px;
            position: relative;
            overflow: hidden;
            padding: 10px 16px;
            cursor: pointer;

            @include ripple($offWhite, false, true);
        }
    }
}
