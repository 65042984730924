@import "../../../../ui/variables";

.mobile {
    display: none !important;
}

.event_list {
    flex: 1;

    .year,
    .month {
        display: flex;
        align-items: center;
        font-size: 22px;
        @include MerriweatherBold;

        &:not(:first-of-type) {
            margin-top: 1.5 * $spacing;
        }

        span {
            flex: unset;
            display: block;
            width: 130px;
        }

        .hr {
            flex: 1;
            background: $darkBlue;
            height: 1px;
        }
    }

    .year {
        font-size: 28px;
        margin-bottom: -$spacing;
    }

    .event_wrapper {
        display: flex;
        flex-direction: row;
        padding: 0.5 * $spacing;
        justify-content: center;
        cursor: pointer;

        &:not(:first-of-type) {
            margin-top: 0.5 * $spacing;
        }

        .date_wrapper {
            display: flex;

            &:after {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background: $darkBlue;
                margin-right: 1.5 * $spacing;
            }

            > div {
                flex: unset;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 98px;

                .day {
                    text-transform: uppercase;
                    font-size: 16px;
                    @include MerriweatherRegular;
                }

                .date {
                    font-size: 32px;
                    @include MerriweatherBold;
                }
            }
        }

        .event {
            flex: 1;
            display: flex;
            flex-direction: row;

            .image {
                flex: unset;
                width: 80px;
                background-position: center;
                background-size: cover;
                margin-right: 24px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .details {
                flex: 1;
                display: flex;
                flex-direction: column;

                .title {
                    margin-bottom: 0.5 * $spacing;
                    @include MerriweatherBold;
                    font-size: 20px;
                }

                .with_img {
                    display: flex;
                    align-items: center;

                    &:not(:last-of-type) {
                        margin-bottom: 6px;
                    }

                    .icon {
                        $size: 24px;
                        flex: unset;
                        width: $size;
                        height: $size;
                        margin-right: $size * 0.5;

                        svg {
                            width: $size;
                            height: $size;
                        }
                    }

                    span {
                    }
                }
            }
        }
    }
}

.wrapper {
    transition: right $transition;
    flex: unset;
    overflow-x: hidden;
    max-width: 70vw;
    border-left: 1px solid $darkBlue;
    border-top: 1px solid $darkBlue;
    width: calc(0.33 * 70vw + 80px);
    right: calc(-1 * (0.33 * 70vw + 80px));
    padding: $spacing;
    background: $offWhite;
    height: 100%;
    position: fixed;

    &.visible {
        right: calc(15vw - 6px);
    }

    .close {
        position: absolute;
        top: $spacing + 4px;
        right: $spacing;
        cursor: pointer;
        width: 24px;
        height: 24px;
    }

    .title {
        @include MerriweatherBold;
        font-size: 24px;
        margin-bottom: $spacing * 0.5;
        width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .with_img {
        display: flex;
        flex-direction: row;
        margin-bottom: $spacing * 0.25;
        align-items: center;

        .img {
            flex: unset;
            margin-right: $spacing * 0.5;
            height: 24px;
        }
    }

    .desc {
        @include OpenSansRegular;
        margin-top: $spacing * 0.5;
        white-space: pre-line;
    }
}

@media only screen and (max-width: 768px) {
    .mobile {
        display: inherit !important;
    }

    .event_list {
        .year {
            display: none;
        }

        .event_wrapper {
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;

            .date_wrapper {
                display: none;
            }

            .event .image {
                display: none;
            }
        }
    }

    .wrapper {
        width: calc(100% - 2 * #{$spacing});
        border-left: 0;
        max-width: unset;
        right: -100vw;
        top: 0 !important;
        z-index: 999;
        border: none;

        &.visible {
            width: 100%;
            right: 0;
        }
    }
}
