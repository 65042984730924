@import "../../../../ui/variables";

.item {
    font-size: 1.2em;
    cursor: pointer;
    margin: 0 0.5 * $spacing;
    transition: $transition;
    color: $darkBlue;

    a {
        transition: $transition;

        &:after {
            transform: scaleX(0) !important;
        }
    }

    &:hover,
    &:hover a,
    &:focus,
    &:focus a,
    a:hover,
    a:focus {
        color: $lightRed;
    }

    &.active,
    &.active a {
        color: $lightRed;
    }
}
