$lightBlue: #151e3f;
$darkBlue: #030027;
$white: #f2f3d9;
$lightRed: #9a4942;
$red: #5b0d00;
$transition: 100ms ease;
$spacing: 24px;
$mobile-spacing: 12px;
$offWhite: #fffff9;

@function darkBlue($alpha) {
    @return rgba(3, 0, 39, $alpha);
}

@function offWhite($alpha) {
    @return rgba(255, 255, 249, $alpha);
}

@function lightRed($alpha) {
    @return rgba(154, 73, 66, $alpha);
}

@mixin MerriweatherBold {
    font-family: "Merriweather", serif;
    font-weight: bold;
}

@mixin MerriweatherLightItalic {
    font-family: "Merriweather", serif;
    font-weight: normal;
    font-style: italic;
}

@mixin MerriweatherRegular {
    font-family: "Merriweather", serif;
    font-weight: normal;
}

@mixin OpenSansRegular {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
}

@mixin OpenSansBold {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
}

@mixin OpenSansExtraBold {
    font-family: "Open Sans", sans-serif;
    font-weight: 900;
}

@mixin ripple($color, $expanded: false, $withHover: false, $size: calc(100% + 14px)) {
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: $color;
        visibility: hidden;
        z-index: 2;
    }

    &:after {
        @if $withHover {
            transition: $transition;
            visibility: visible;
            opacity: 0;

            @if $expanded {
                width: $size;
                padding-bottom: $size;
            } @else {
                width: 100%;
                padding-bottom: 100%;
            }
        }
    }

    &:not(:active):before {
        animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
        transition: visibility 0.4s step-end;
    }

    &:active:before {
        visibility: visible;
    }

    &:hover:after {
        @if $withHover {
            opacity: 0.1;
        }
    }

    @keyframes ripple {
        0% {
            width: 0;
            padding-bottom: 0;
            opacity: 0.2;
        }

        100% {
            @if $expanded {
                width: $size;
                padding-bottom: $size;
            } @else {
                width: 100%;
                padding-bottom: 100%;
            }
            opacity: 0;
        }
    }
}

@mixin line-on-hover($color: $darkBlue) {
    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        height: 2px;
        width: 100%;
        display: block;
        background: $color;
        transition: $transition;
        transform: scaleX(0);
        transform-origin: left center;
    }

    &:hover:after,
    &:focus:after {
        transform: scaleX(1);
    }
}
