@use "sass:math";

@import "../../../../ui/variables";

.wrapper {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 3 * $spacing 0 3 * $spacing;

    .cart {
        display: flex;
        flex-direction: row;

        .column {
            display: flex;
            flex-direction: column;
            flex: 1;

            &:not(:last-of-type) {
                margin-right: $spacing;
            }

            &:not(:first-of-type) {
                margin-left: $spacing;
            }

            form {
                .row {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;

                    > * {
                        margin-bottom: 0;

                        &:not(:last-of-type) {
                            margin-right: $spacing;
                            flex: 1;
                        }

                        &:last-of-type {
                            width: 170px;
                        }
                    }
                }
            }

            .cd {
                display: flex;
                flex-direction: row;
                padding: $spacing 0;
                align-items: center;

                &:first-of-type {
                    padding-top: 0;
                }

                img {
                    width: 100px;
                    height: 100px;
                    margin-right: $spacing;
                }

                .details_wrapper {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .title {
                        @include MerriweatherBold;
                        margin-bottom: math.div($spacing, 3);
                    }

                    .desc {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 180px;
                        overflow: hidden;
                    }
                }

                .trash {
                    margin: 0 $spacing;
                    cursor: pointer;
                }

                .amount {
                    margin-right: $spacing;
                    white-space: nowrap;
                }

                .price {
                    @include MerriweatherBold;
                    font-size: 16px;
                }
            }

            .confirming {
                display: flex;
                flex-direction: column;
                line-height: 30px;

                .confirming_wrapper {
                    display: flex;
                    flex-direction: row;
                    font-size: 18px;

                    .column {
                        display: flex;
                        flex-direction: column;

                        &:first-of-type {
                            @include MerriweatherBold;
                            flex: 0;
                        }

                        &:not(:last-of-type) {
                            margin-right: $spacing;
                        }
                    }
                }
            }

            .totals_wrapper {
                display: flex;
                flex-direction: row;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    height: 1px;
                    background: $darkBlue;
                    width: 100%;
                    margin-bottom: $spacing;
                    position: absolute;
                }

                .cart_wrapper {
                    display: flex;
                    flex-direction: column;
                    margin-top: $spacing;

                    &:first-of-type {
                        flex: 1;
                        text-align: right;
                    }

                    > * {
                        line-height: 30px;

                        &:not(:last-of-type) {
                            margin-bottom: $spacing * 0.5;
                        }
                    }

                    .title {
                        @include OpenSansRegular;
                        font-size: 16px;
                    }

                    .amount {
                        @include MerriweatherBold;
                        text-align: right;
                        margin-left: $spacing;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        width: calc(100vw - 2 * #{$spacing});

        .cart {
            flex-direction: column;

            .column {
                &:not(:last-of-type) {
                    margin-right: 0;
                }

                &:not(:first-of-type) {
                    margin-top: 2 * $spacing;
                    margin-left: 0;
                }

                form .row {
                    flex-direction: column;

                    > * {
                        margin-bottom: unset;

                        &:not(:last-of-type) {
                            margin-right: 0;
                            flex: 1;
                            margin-bottom: 10px;
                        }

                        &:last-of-type {
                            width: unset;
                        }
                    }
                }
            }

            .cd {
                img {
                    display: none;
                }

                .details_wrapper {
                    .title {
                        margin-bottom: 0;
                    }

                    .desc {
                        display: none;
                    }
                }
            }

            .confirming .column {
                margin-top: 0;
            }
        }
    }
}
