@import "../../../../ui/variables";

.hamburger_wrapper {
    display: none;
}

.nav {
    position: absolute;
    left: $spacing;
    right: $spacing;
    top: $spacing;
    padding: 0.75 * $spacing $spacing;
    background: $offWhite;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 20px 10px darkBlue(0.1);
    z-index: 9;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        color: $darkBlue;
        align-items: center;
        @include MerriweatherBold;

        &.languages li {
            text-transform: uppercase;
            font-size: 1.2em;
            position: relative;
            cursor: pointer;
            display: block;
            margin: 0 0.5 * $spacing;
            @include line-on-hover();

            &.active:after {
                transform: scaleX(1);
            }
        }

        .shopping_cart {
            display: flex;
            flex-direction: row;

            &:before {
                content: "";
                display: block;
                background: $lightRed;
                width: 2px;
                height: 20px;
                margin: 0 $spacing 0 $spacing * 0.5;
            }

            a {
                display: flex;
                flex-direction: row;

                &:after {
                    display: none;
                }
            }

            .icon {
                $size: 20px;
                width: $size;
                height: $size;

                svg {
                    width: $size;
                    height: $size;
                }
            }

            .amount {
                color: $lightRed;
                margin-left: $spacing * 0.5;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .hamburger_wrapper {
        position: absolute;
        left: 0;
        right: 0;
        background: $offWhite;
        top: 0;
        content: "";
        display: block;
        width: 100vw;
        height: 19px + (1.5 * $spacing);
        z-index: 999;

        .hamburger {
            display: block;
            position: relative;
            top: $spacing * 0.75;
            left: $spacing;
            cursor: pointer;
            z-index: 999;
            height: 19px;
            width: 40px;

            &.open {
                span:nth-of-type(1) {
                    transform: translateY(13px) rotate(45deg);
                }

                span:nth-of-type(2) {
                    height: 0;
                }

                span:nth-of-type(3) {
                    transform: translateY(5px) rotate(-45deg);
                }
            }

            > span {
                display: block;
                height: 3px;
                width: 40px;
                background: $darkBlue;
                transition: transform $transition;
                z-index: 99999;

                &:not(:last-of-type) {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .nav {
        transform: translateX(-100%);
        transition: $transition;
        flex-direction: column;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        justify-content: flex-start;

        &.visible {
            transform: translateX(0);
        }

        ul {
            width: 100%;

            &.languages {
                margin-top: 2 * $spacing;
                justify-content: space-around;
                padding: $spacing 0;
                margin-bottom: 0;
            }

            &:not(.languages) {
                flex-direction: column;

                li {
                    width: 100%;
                    display: block;
                    padding: 0.5 * $spacing;
                }
            }

            .shopping_cart:before {
                height: 2px;
                width: 100%;
                margin: 0 0 $spacing;
            }
        }

        .languages {
            margin-bottom: 2 * $spacing;
        }
    }
}
